@import "filepond/dist/filepond.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    @apply font-montserrat text-blue-900;
}

[x-cloak] { display: none !important; }

/**
 * TipTap
 */
.ProseMirror {
    @apply outline-none p-3 min-h-[20rem];
}

.ProseMirror * + p {
    @apply pt-3;
}

.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
    @apply text-gray-300;
}

/**
 * Filepond
 */
.error .filepond--drop-label {
    @apply text-red-500;
}

.filepond--label-action {
    @apply decoration-blue-900;
}

.error .filepond--label-action {
    @apply decoration-red-500;
}

.filepond--drop-label label {
    font-weight: 400;
    @apply text-lg text-blue-900;
    line-height: 1.35rem !important;
}

.filepond--panel-root {
    background: none;
}

.filepond--credits {
    display: none !important;
}

.filepond--root {
    margin: 0 !important;
    border: 2px solid;
    box-sizing: content-box;
    @apply bg-[#f8fbff] border-blue-800;
}

.error .filepond--root {
    margin: 0 !important;
    border-radius: 7px;
    box-sizing: content-box;
    @apply outline outline-offset-1 outline-1 outline-red-100;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
    @apply bg-yellow-500;
}

/**
 * Fields Editor
 */

.text__bloc {
    @apply relative;
}

.text__flex {
    @apply relative flex items-center justify-between gap-3 pt-24;
}

.text__bloc-label,
.text__textarea-label {
    @apply absolute z-10 flex items-center justify-center h-6 bg-white  border border-b-[3px] border-gray-200 rounded-sm text-xs px-2 top-0 left-0;
}

.text__bloc-container {
    @apply relative top-6 rounded-sm pt-0;
}

.text__bloc-line {
    @apply h-8 flex items-center border-b border-blue-300 outline-none w-full;
}

.text__textarea {
    @apply relative mt-12;
}

.text__textarea-content {
    @apply w-full min-h-[2rem] border-b border-blue-300 relative top-5 outline-none;
}

.text__bloc-canvas {
    @apply bg-white relative -top-[3px]  border-2 border-gray-100;
}

.text__input {
    @apply bg-blue-100 rounded-md inline-block px-2 outline-none;
}

.text__input > div {
    @apply outline-none;
}

.text__input > div:empty:not(:focus):before {
    content:attr(aria-placeholder);
}

/**
 * Breadcrump
 */

.breadcrumb {
    @apply flex justify-between items-start text-sm lg:text-base w-full max-w-7xl mx-auto px-6 sm:px-12 md:px-16 overflow-hidden gap-3 divide-x divide-gray-200;
}

.breadcrumb-item {
    @apply relative flex items-center justify-center gap-3 flex-1;
}

.breadcrumb-item > div {
    @apply relative z-20 px-2 bg-white;
}

.breadcrumb-item > .picto {
    @apply h-8 w-8 bg-blue-900 flex items-center justify-center text-white;
}

.breadcrumb-item > .text {
    @apply hidden lg:block;
}

.breadcrumb-item.active > .picto {
    @apply bg-yellow-500;
}

.breadcrumb-item.active > .text {
    @apply text-yellow-500;
}

/**
 * Form
 */

.select {
    @apply relative;
}

.select > select {
    border-radius: 0;
    @apply relative cursor-pointer z-10;
}

.select:before {
    content: "\2193";
    @apply absolute w-8 h-14 md:h-10 bottom-[2px] right-0 flex z-50 items-center justify-center text-xl text-white font-semibold;
}

/**
 * Article
 */
article > h2 + h3,
article > h2 + h4,
article > h2 + h5,
article > h3 + h2,
article > h3 + h4,
article > h3 + h5,
article > h4 + h2,
article > h4 + h3,
article > h4 + h5,
article > h5 + h2,
article > h5 + h3,
article > h5 + h4 {
    @apply mt-1.5 sm:mt-3;
}

article > h1 + p,
article > h1 + ul,
article > h1 + ol,
article > h1 + blockquote,
article > h1 + dl,
article > h2 + p,
article > h2 + ul,
article > h2 + ol,
article > h2 + blockquote,
article > h2 + dl,
article > h3 + p,
article > h3 + ul,
article > h3 + ol,
article > h3 + blockquote,
article > h3 + dl,
article > h4 + p,
article > h4 + ul,
article > h4 + ol,
article > h4 + blockquote,
article > h4 + dl,
article > h5 + p,
article > h5 + ul,
article > h5 + ol,
article > h5 + blockquote,
article > h5 + dl {
    @apply mt-1.5 sm:mt-3 mb-1.5 sm:mb-3;
}

article > dl + h1,
article > p + h2,
article > ul + h2,
article > ol + h2,
article > blockquote + h2,
article > dl + h2,
article > p + h3,
article > ul + h3,
article > ol + h3,
article > blockquote + h3,
article > dl + h3,
article > p + h4,
article > ul + h4,
article > ol + h4,
article > blockquote + h4,
article > dl + h4,
article > p + h5,
article > ul + h5,
article > ol + h5,
article > blockquote + h5,
article > dl + h5 {
    @apply mt-6 sm:mt-9;
}

article h1, .h1,
article h2, .h2,
article h3, .h3,
article h4, .h4,
article h5, .h5 {
    line-height: 1.15em !important;
}

article h1, .h1 {
    @apply text-3xl md:text-4xl mb-6 sm:mb-6;
}

article h2, .h2 {
    @apply text-xl md:text-2xl;
}

article h3, .h3 {
    @apply text-lg md:text-xl;
}

article h4, .h4 {
    @apply text-base md:text-xl;
}

article p,
article ul,
article ol,
article blockquote {
    @apply text-sm;
}

article > p + p,
article > p + ul,
article > p + ol,
article > p + blockquote,
article > p + dl,
article > ul + p,
article > ul + ul,
article > ul + ol,
article > ul + blockquote,
article > ul + dl,
article > ol + p,
article > ol + ul,
article > ol + ol,
article > ol + blockquote,
article > ol + dl,
article > blockquote + p,
article > blockquote + ul,
article > blockquote + ol,
article > blockquote + blockquote,
article > blockquote + dl,
article > dl + p,
article > dl + ul,
article > dl + ol,
article > dl + blockquote,
article > dl + dl {
    @apply mb-1.5 sm:mb-3;
}

article > p + dl {
    @apply mt-6 sm:mt-12;
}

article p {
    @apply text-justify;
}

article ul li {
    @apply ml-5;
}

article ul li:before {
    content: "— ";
    @apply -ml-5;
}

article ol {
    @apply list-decimal list-inside;
}

article blockquote {
    @apply border-l-2 border-blue-300 pl-6;
}

article dl {
    @apply cursor-pointer border-2 border-gray-100 p-3;
}

article dl dt {
    @apply relative pr-8 font-semibold text-lg;
}

article dl dd {
    @apply mt-3 text-sm;
}

strong {
    @apply font-semibold;
}

article.text-small h1, .h1 {
    @apply text-4xl md:text-3xl;
}

article.text-small h2, .h2 {
    @apply text-base;
}

article.text-small h3, .h3 {
    @apply text-base;
}

article.text-small h4, .h4 {
    @apply text-sm;
}

article.text-small h5, .h5 {
    @apply text-sm;
}

article.text-small p,
article.text-small ul,
article.text-small ol,
article.text-small dl,
article.text-small blockquote {
    @apply text-xs;
}

article.text-small > dl + h1,
article.text-small > p + h2,
article.text-small > ul + h2,
article.text-small > ol + h2,
article.text-small > blockquote + h2,
article.text-small > dl + h2,
article.text-small > p + h3,
article.text-small > ul + h3,
article.text-small > ol + h3,
article.text-small > blockquote + h3,
article.text-small > dl + h3,
article.text-small > p + h4,
article.text-small > ul + h4,
article.text-small > ol + h4,
article.text-small > blockquote + h4,
article.text-small > dl + h4,
article.text-small > p + h5,
article.text-small > ul + h5,
article.text-small > ol + h5,
article.text-small > blockquote + h5,
article.text-small > dl + h5 {
    @apply mt-2;
}

/**
 * Letter Model
 */

.char {
    @apply w-2 h-3 inline-block bg-yellow-500;
}

.varkey {
    @apply border-b-2 border-blue-300 inline-flex items-center text-blue-800;
}

/**
 * Toggle checkbox
 */

.toggle-checkbox {
    @apply relative bg-gray-200 rounded-full appearance-none w-12 h-6 cursor-pointer;
}

.toggle-checkbox-amber {
    @apply bg-yellow-200;
}

.toggle-checkbox::before {
    content: "";
    @apply absolute left-0 block bg-white border-2 border-gray-100 rounded-full w-6 h-6;
}

.toggle-checkbox-amber::before {
    @apply border-yellow-200;
}

.toggle-checkbox:checked, .toggle-checkbox-amber:checked {
    @apply bg-blue-900;
}

.toggle-checkbox:checked::before {
    @apply left-auto right-0;
}

/*
 * Listing documents
 */

.shadow-document {
    box-shadow: 0 0.0625em 0.125em -0.0625em rgba(0, 0, 0, 0.25);
}
